.wallet-adapter-button {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  white-space: nowrap;
  background-image: radial-gradient(
    circle at 100% 0%,
    rgba(94, 138, 243, 1) 15%,
    rgba(243, 61, 223, 1)
  );
  padding: 1em 2em;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 100px;
  transition: 0.5s all;
}

.wallet-adapter-button:not([disabled]):hover {
  background-image: radial-gradient(circle at 100% 0%, rgb(80, 122, 219) 15%, rgb(209, 49, 190));
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(3, 168, 124, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(3, 168, 124, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(3, 168, 124, 0);
  }
}
.button:hover {
  background-image: radial-gradient(circle at 100% 0%, rgb(80, 122, 219) 15%, rgb(209, 49, 190));
}
.button {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: radial-gradient(
    circle at 100% 0%,
    rgba(94, 138, 243, 1) 15%,
    rgba(243, 61, 223, 1)
  );
  /* background: #d228fa; */
  padding: 1em 2em;
  border: none;
  color: white;
  font-size: 1.2em !important;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 100px;
  transition: 0.5s all;
}

.pulse {
  animation: pulse 2s infinite;
}
.MuiButton-label{
  color : white; 
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(3, 168, 124, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(3, 168, 124, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(3, 168, 124, 0);
  }
}
