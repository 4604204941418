@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TitlingGothicFBCond-Regular';
  src: url('./images/font/TitlingGothicFBCond-Regular.woff2') format('woff2'),
    url('./images/font/TitlingGothicFBCond-Regular.woff') format('woff'); /* chrome、firefox */
}
html,
body {
  /* background-color: white; */
  height: 100%;
  width: 100%;
  background: url(./images/bg_body_pattern.png) #fff;
}


#icon-eye {
  width: 250px;
  margin: auto;
  padding: 20px;
  overflow: auto;
  color: white;
}
.diagonal-box {
  background-image: linear-gradient(45deg, #6303b1, #ff0099);
  /* height: 220px; */
  transform: skewY(-9deg);
  z-index: 0;
  height: 500px;
  top: -4rem;
}
.content {
  max-width: 50em;
  margin: 0 auto;
}
.slider img {
  width: 100%;
  height: 50vw;
  object-fit: cover;
}
.zoom {
  transition: 0.5s all;
}
.zoom:hover {
  transform: scale(1.2, 1.2);
}
.style_title {
  font-family: 'TitlingGothicFBCond-Regular', sans-serif !important;
}

.style_orange {
  background: linear-gradient(
    90deg,
    rgba(227, 107, 20, 1) 0%,
    rgba(255, 162, 0, 1) 65%,
    rgba(255, 233, 32, 1) 97%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.style_pink {
  background: linear-gradient(
    90deg,
    rgba(255, 62, 28, 1) 0%,
    rgba(255, 0, 194, 1) 67%,
    rgba(221, 43, 31, 1) 96%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.style_blue {
  background: linear-gradient(
    90deg,
    rgba(84, 186, 243, 1) 0%,
    rgba(0, 255, 226, 1) 78%,
    rgba(107, 236, 234, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
